import 'react-day-picker/lib/style.css';
import './delivery-date-step.scss';

import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, ResponsiveTextArea } from '../../../../styled-components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  useApplicationStatus,
  useOrderFormContext,
  useProductType,
  useSettingsService,
  useStepper,
} from '../../../../hooks';
import OrderDetailsStepperContext from '../../../../contexts/stepper/OrderDetailsStepperContext';
import { DieselProductName, FuelProductName, WoodProductName } from '../../../../assets/constants/Constants';
import { Form } from 'react-bootstrap';
import OrderStepperContext from '../../../../contexts/stepper/OrderStepperContext';
import WeekPicker from './week-picker/WeekPicker';
import {
  getCurrentWeekNumberInYear,
  getMonthNumberFromWeekNumber,
  howManyWeeksInYear,
} from '../../../../utils/date-handling';
import Step from '../../../stepper/step/Step';
import styled from 'styled-components';
import moment from 'moment';
import StepHeader from '../../../common/step-header/StepHeader';
import { Calendar } from '../../../../models/offers-service/Calendar';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';

/**
 * Styles for the main page layout.
 */
const Layout = styled(Flex)`
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
`;

/**
 * Styles for the layout of the calendars.
 */
const WeekPickerLayout = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;

  @media (min-width: 992px) {
    flex-direction: ${({ direction }) => direction};
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const PriceChangeInfo = styled.p.attrs(() => ({
  className: 'mt-2',
}))<{}>`
  margin-bottom: 1.5em;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

/**
 * This interface describes the values that are input in this form.
 */
interface DeliveryDateFormValues {
  deliveryDates?: Date[];
}

/**
 *
 * The Component describing the step that allows to input the delivery date details.
 *
 * @returns the Component to be used in JSXs.
 */
export default function DeliveryDateStep() {
  const { t } = useTranslation();

  const { setApplicationStatus, deleteApplicationStatus } = useApplicationStatus();

  /**
   * This is the Yup schema that Formik is going to follow for validation.
   */
  const deliveryDateValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        deliveryDates: Yup.array().of(Yup.date()).min(7).required(t('DeliveryDate.ValidationErrors.DeliveryDate')),
      }),
    [t],
  );

  const { prevStep } = useStepper(OrderStepperContext)!;
  const { nextStep } = useStepper(OrderDetailsStepperContext)!;

  const settingsService = useSettingsService();
  const language = useCurrentLanguage();
  const productTypeString = useProductType();

  const {
    priceDetails,

    remarks,
    setRemarks,

    deliveryDates,
    setDeliveryDates,

    isTimeoutReached,
  } = useOrderFormContext();

  const calendarInfo = useMemo<Calendar | null>(() => {
    if (!priceDetails) {
      return null;
    }

    return priceDetails.calendar ?? priceDetails.disporegion ?? null;
  }, [priceDetails]);

  const initialValues = useMemo<DeliveryDateFormValues>(
    () => ({
      deliveryDates: deliveryDates,
    }),
    [deliveryDates],
  );

  const submitAndNextStep = useCallback(() => {
    settingsService.getSystemInfo(productTypeString, language, t).then((systemInfo) => {
      if (!systemInfo.isSystemOnline) {
        prevStep();
        setDeliveryDates(undefined);

        deleteApplicationStatus<'deliveryDates'>(['deliveryDates']);

        return;
      }

      setApplicationStatus({
        deliveryDates: deliveryDates,
        remarks: remarks,
      });

      nextStep();
    });
  }, [
    settingsService,
    productTypeString,
    language,
    t,
    setApplicationStatus,
    deliveryDates,
    remarks,
    nextStep,
    prevStep,
    setDeliveryDates,
    deleteApplicationStatus,
  ]);

  /**
   * This callback constructs the available delivery weeks for the customer to choose from.
   */
  const buildWeekNumbers = useCallback(
    () =>
      Array.from<number>({ length: calendarInfo?.showWeeks ?? 0 })
        .fill(calendarInfo?.deliveryWaitTime ?? 0)
        .map((startingWeek, index) => startingWeek + index)
        .map((weekToAdd) => {
          const weekNumber = getCurrentWeekNumberInYear() + weekToAdd;
          const year = moment().isoWeekYear();

          return {
            year: weekNumber - howManyWeeksInYear(year) <= 0 ? year : year + 1,
            month: getMonthNumberFromWeekNumber(weekNumber),
            weekNumber: weekNumber - howManyWeeksInYear(year) <= 0 ? weekNumber : weekNumber - howManyWeeksInYear(year),
          };
        }),
    [calendarInfo],
  );

  useEffect(() => {
    async function init() {
      const systemInfo = await settingsService.getSystemInfo(productTypeString, language, t);
      if (!systemInfo || !systemInfo.isSystemOnline) {
        setDeliveryDates(undefined);

        deleteApplicationStatus<'deliveryDates'>(['deliveryDates']);
        prevStep();
      }

      if (isTimeoutReached()) {
        prevStep();

        return;
      }
    }

    init();
  }, []);

  return (
    <Step>
      <Flex direction="column" className="justify-content-between align-items-start">
        <StepHeader />

        <Formik
          onSubmit={submitAndNextStep}
          initialValues={initialValues}
          validationSchema={deliveryDateValidationSchema}
        >
          {({ handleSubmit, setValues, errors }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} className="align-self-stretch">
                <Layout style={{ justifyContent: 'space-between' }}>
                  <Flex direction="column" style={{ flex: 1 }}>
                    {errors.deliveryDates && <span className="text-danger">{errors.deliveryDates}</span>}
                    <label className="form-label">{t('DeliveryDate.DesiredDeliveryWeek')}</label>

                    <WeekPickerLayout direction="column">
                      <WeekPicker
                        selectedDates={deliveryDates}
                        setSelectedDates={setDeliveryDates}
                        weekNumbers={buildWeekNumbers()}
                        alertMessageDe={calendarInfo?.textDe ?? ''}
                        alertMessageFr={calendarInfo?.textFr ?? ''}
                      />
                    </WeekPickerLayout>

                    <PriceChangeInfo>
                      <em>
                        {productTypeString === FuelProductName
                          ? t('DeliveryDate.PriceChangeInfoFuel')
                          : productTypeString === DieselProductName
                          ? t('DeliveryDate.PriceChangeInfoDiesel')
                          : t('DeliveryDate.PriceChangeInfoWood')}
                      </em>
                    </PriceChangeInfo>
                  </Flex>

                  <Flex direction="column" className="align-items-start" style={{ flex: 1 }}>
                    <label className="form-label">{t('DeliveryDate.PaymentMethodLabel')}</label>
                    <span className="mb-4">{t('DeliveryDate.PaymentMethod')}</span>

                    <label className="form-label">{t('DeliveryDate.Remarks')}</label>
                    <ResponsiveTextArea rows={3} value={remarks} onChange={(e) => setRemarks(e.currentTarget.value)} />
                  </Flex>
                </Layout>

                <div className="d-flex mt-4">
                  <button
                    className="btn btn-secondary me-1"
                    type="button"
                    onClick={() => {
                      setDeliveryDates(undefined);

                      deleteApplicationStatus<'deliveryDates'>(['deliveryDates']);

                      prevStep();
                    }}
                  >
                    <span>{'< ' + t('DeliveryDate.Back')}</span>
                  </button>
                  <button
                    className="btn btn-primary ms-1"
                    type="submit"
                    onClick={() =>
                      setValues({
                        deliveryDates,
                      })
                    }
                  >
                    <span>{t('DeliveryDate.Next') + ' >'}</span>
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Step>
  );
}
