import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import DayPicker, { Modifiers } from 'react-day-picker';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { getWeekNumberInYear } from '../../../../../utils/date-handling';
import useCurrentLanguage from '../../../../../hooks/useCurrentLanguage';
import { useTranslation } from 'react-i18next';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/de';
import 'moment/locale/fr';

/**
 *
 * This function gets the days of the week starting from a set date,
 * passed as parameter.
 *
 * @param weekStart - The starting point for getting the week days.
 *
 * @returns an array of dates.
 */
function getWeekDays(weekStart: Date) {
  const days = [weekStart];
  for (let i = 1; i < 7; i++) {
    days.push(moment(weekStart).add(i, 'days').toDate());
  }

  return days;
}

/**
 * This interface describes the starting date of a week and its ending day.
 */
interface WeekRange {
  from: Date;
  to: Date;
}

/**
 *
 * This function gets the days of a week that contains the starting date,
 * passed as a parameter.
 *
 * @param date - The starting date from which we want to extract the
 *               days of its week.
 *
 * @returns an object containing two properties: from and to, indicating respectively
 *          the starting date of the week, and the end date.
 */
function getWeekRange(date: Date): WeekRange {
  return {
    from: moment(date).startOf('isoWeek').toDate(),
    to: moment(date).endOf('isoWeek').toDate(),
  };
}

/**
 * This interface describes the shape for the radio button weeks
 * to be displayed in the calendar.
 */
interface WeekNumber {
  year: number;
  month: number;
  weekNumber: number;
}

/**
 * This interface describes the props that can and have to be passed
 * to the WeekPicker Component.
 */
interface WeekPrickerProps {
  selectedDates: Date[] | undefined;
  setSelectedDates: React.Dispatch<SetStateAction<Date[] | undefined>>;
  weekNumbers: WeekNumber[];

  /**
   * The message to display from the disporegion upon week selection in German.
   */
  alertMessageDe: string;

  /**
   * The message to display from the disporegion upon week selection in French.
   */
  alertMessageFr: string;
}

export default function WeekPicker({
  selectedDates,
  setSelectedDates,
  weekNumbers,
  alertMessageDe,
  alertMessageFr,
}: WeekPrickerProps) {
  const language = useCurrentLanguage();
  const { t } = useTranslation();

  const [hoverRange, setHoverRange] = useState<{ [key: number]: WeekRange }>({});
  const [checkedWeekNumbers, setCheckedWeekNumbers] = useState<number[]>([]);

  const daysAreSelected = useMemo(() => (selectedDates ? selectedDates.length > 0 : false), [selectedDates]);

  const modifiers = useCallback(
    (calendarKey: number) => ({
      [calendarKey]: {
        hoverRange: hoverRange[calendarKey],
        selectedRange: daysAreSelected
          ? {
              from: selectedDates![0],
              to: selectedDates![6],
            }
          : undefined,
        hoverRangeStart: hoverRange[calendarKey] && hoverRange[calendarKey].from,
        hoverRangeEnd: hoverRange[calendarKey] && hoverRange[calendarKey].to,
        selectedRangeStart: daysAreSelected ? selectedDates![0] : undefined,
        selectedRangeEnd: daysAreSelected ? selectedDates![6] : undefined,
      },
    }),
    [hoverRange, daysAreSelected, selectedDates],
  );

  const weekMonths = useMemo(() => [...new Set(weekNumbers.map((week) => week.month))], [weekNumbers]);

  const removeSelectedDays = useCallback(
    (daysToRemove: Date[]) => {
      let newSelectedDates = selectedDates ? [...selectedDates] : [];

      newSelectedDates = newSelectedDates.filter(
        (selectedDate) => !daysToRemove.some((dayToRemove) => dayToRemove.getTime() === selectedDate.getTime()),
      );

      return newSelectedDates.length === 0 ? undefined : newSelectedDates;
    },
    [selectedDates],
  );

  const addSelectedDays = useCallback(
    (daysToAdd: Date[]) => {
      const newSelectedDates = selectedDates ? [...selectedDates] : [];

      const filteredDaysToAdd = daysToAdd.filter(
        (dayToAdd) => !newSelectedDates.some((selectedDate) => selectedDate.getTime() === dayToAdd.getTime()),
      );

      return [...newSelectedDates, ...filteredDaysToAdd];
    },
    [selectedDates],
  );

  const shouldShowAlert = useCallback(() => {
    switch (language) {
      case 'de':
        return !!alertMessageDe.trim();

      case 'fr':
        return !!alertMessageFr.trim();

      default:
        return false;
    }
  }, [language, alertMessageDe, alertMessageFr]);

  const updateWeekNumberAllowed = useCallback(
    (nextWeeknumber: number, showMessage = true) => {
      if (weekNumbers.findIndex((o) => o.weekNumber === nextWeeknumber) === -1) {
        return false;
      }

      const lastNumberInSequence = Math.max(...checkedWeekNumbers);
      const firstNumberInSequence = Math.min(...checkedWeekNumbers);

      if (
        checkedWeekNumbers.length === 0 ||
        nextWeeknumber === lastNumberInSequence ||
        nextWeeknumber === firstNumberInSequence ||
        nextWeeknumber === lastNumberInSequence + 1 ||
        nextWeeknumber === firstNumberInSequence - 1
      ) {
        return true;
      }

      if (showMessage) {
        alert(t('Weekpicker.OnlysuccessiveWeeknumber'));
      }
      return false;
    },
    [weekNumbers, checkedWeekNumbers, t],
  );

  const selectWeek = useCallback(
    (week) => {
      if (!updateWeekNumberAllowed(week.weekNumber)) return;

      const daysOfWeek = getWeekDays(
        getWeekRange(moment().year(week.year).startOf('year').isoWeek(week.weekNumber).startOf('isoWeek').toDate())
          .from,
      );

      const wasChecked = checkedWeekNumbers.some((checkedNumber) => checkedNumber === week.weekNumber);

      if (wasChecked) {
        setSelectedDates(removeSelectedDays(daysOfWeek));
      } else {
        shouldShowAlert() && alert(language === 'de' ? alertMessageDe : alertMessageFr);

        setSelectedDates(addSelectedDays(daysOfWeek));
      }

      setCheckedWeekNumbers(checkedWeekNumbers.filter((checkedNumber) => checkedNumber !== week.weekNumber));
    },
    [
      addSelectedDays,
      alertMessageDe,
      alertMessageFr,
      checkedWeekNumbers,
      language,
      removeSelectedDays,
      setSelectedDates,
      shouldShowAlert,
      updateWeekNumberAllowed,
    ],
  );

  const selectWeekByWeek = useCallback(
    (weekNumber, days) => {
      selectWeek({ weekNumber: weekNumber, year: days[0].getFullYear() });
    },
    [selectWeek],
  );

  const selectWeekByDay = useCallback(
    (date: Date) => {
      selectWeek({ weekNumber: getWeekNumberInYear(date), year: date.getFullYear() });
    },
    [selectWeek],
  );

  const handleDayEnter = useCallback(
    (date: Date, month: number) => {
      setHoverRange({ ...hoverRange, [month]: getWeekRange(date) });
    },
    [setHoverRange, hoverRange],
  );

  const handleDayLeave = useCallback(
    (month: number) => {
      const newHoverRange = { ...hoverRange };
      delete newHoverRange[month];

      setHoverRange(newHoverRange);
    },
    [hoverRange, setHoverRange],
  );

  /**
   * This callback calculates the initial position from the top
   * side by the week calendar for the week radio buttons.
   */
  const calculatePaddingTop = useCallback(
    (month: number) => {
      const monthWeeksToChooseFrom = weekNumbers.filter((week) => week.month === month);

      if (monthWeeksToChooseFrom.length === 0) {
        console.error("Couldn't find the first week to display for the month indicated. returning 0.");

        return 'calc(1em + 27.6px + 0.5em + 35px)';
      }

      const firstWeekOfIndicatedMonth = moment()
        .year(weekNumbers.find((weekDetails) => weekDetails.month === month)?.year ?? moment().year())
        .month(month - 1)
        .startOf('month')
        .isoWeek();

      const firstWeekOfMonthToChooseFrom = monthWeeksToChooseFrom[0].weekNumber;

      return `calc(1em + 27.6px + 0.5em + 35px + (42px * ${firstWeekOfMonthToChooseFrom - firstWeekOfIndicatedMonth}))`;
    },
    [weekNumbers],
  );

  useEffect(() => {
    setCheckedWeekNumbers([...new Set(selectedDates?.map((selectedDate) => moment(selectedDate).isoWeek()))]);
  }, [selectedDates]);

  return (
    <>
      {weekMonths.map((month) => (
        <div key={month} className="d-flex">
          <div
            className={`d-flex flex-column align-self-stretch`}
            style={{ paddingTop: calculatePaddingTop(month), paddingBottom: '1em' }}
          >
            {weekNumbers
              .filter((week) => week.month === month)
              .map((week) => (
                <Form.Check
                  key={week.weekNumber}
                  className="py-2 m-0"
                  style={{ border: '1px solid transparent' }}
                  type="radio"
                  id={`id-${week.weekNumber}`}
                  label={week.weekNumber}
                  value={week.weekNumber}
                  checked={checkedWeekNumbers.some((checkedNumber) => checkedNumber === week.weekNumber)}
                  onChange={() => {
                    selectWeek(week);
                  }}
                  onClick={() => {
                    selectWeek(week);
                  }}
                />
              ))}
          </div>
          <DayPicker
            className="SelectedWeek align-self-start"
            localeUtils={MomentLocaleUtils}
            locale={language}
            canChangeMonth={false}
            firstDayOfWeek={1}
            disabledDays={[
              {
                before: moment()
                  .year(
                    Math.min.apply(
                      Math,
                      weekNumbers.map((weekDetails) => weekDetails.year),
                    ),
                  )
                  .isoWeek(weekNumbers[0].weekNumber)
                  .startOf('isoWeek')
                  .toDate(),
                after: moment()
                  .year(
                    Math.min.apply(
                      Math,
                      weekNumbers.map((weekDetails) => weekDetails.year),
                    ),
                  )
                  .isoWeek(weekNumbers[0].weekNumber)
                  .add(weekNumbers.length - 1, 'weeks')
                  .endOf('isoWeek')
                  .toDate(),
              },
              { daysOfWeek: [0, 6] },
            ]}
            month={moment()
              .year(weekNumbers.find((weekDetails) => weekDetails.month === month)?.year ?? moment().year())
              .month(month - 1)
              .toDate()}
            selectedDays={selectedDates}
            showOutsideDays
            showWeekNumbers
            modifiers={modifiers(month)[month] as unknown as Partial<Modifiers>}
            onWeekClick={selectWeekByWeek}
            onDayClick={selectWeekByDay}
            onDayMouseEnter={(date) => handleDayEnter(date, month)}
            onDayMouseLeave={() => handleDayLeave(month)}
          />
        </div>
      ))}
    </>
  );
}
